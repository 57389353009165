.block.filter {
    .filter-content {
        .filter-options-content {
            .amshopby-search-box {
                position: relative;

                &::after {
                    position: absolute;
                    right: $grid-compact-gutter-width;
                    top: 0;
                    font-family: $icon-font;
                    color: #101820;
                    font-weight: $font-weight__bold;
                    content: "\f002";
                    line-height: 48px;
                }

                input {
                    height: 48px;
                    border: 1px solid #232e5c;
                    padding-right: 50px;
                }
            }
            .item {
                display: flex;
                align-items: center;

                a[class*='am-filter-item'] {
                    padding-left: 0;
                }

                input[type=checkbox] {
                    appearance: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $brand__secondary__color;
                    position: static;
                    border-radius: 6px;
                    width: 20px;
                    height: 20px;
                    opacity: 1;
                    cursor: pointer;

                    &:checked {
                        background-color: $brand__secondary__color;
                        border-color: $brand__secondary__color;

                        &::before {
                            content: $icon-checkmark;
                            font-family: $icon-font;
                            color: $color-white;
                            font-size: $font-size__s;
                        }
                    }
                }
            }
        }
    }
}